import IdentityApi from 'api/identity/identity.api';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loginSuccess, logoutAction, setAuthenticated } from 'redux/controller';
import { useDispatchRoot, useSelectorRoot } from 'redux/store';
import { map } from 'rxjs/operators';

const Auth = ({ children }: Props): JSX.Element => {
    const dispatch = useDispatchRoot();
  const history = useHistory();
  const location = useLocation();
  const { authenticated } = useSelectorRoot((state) => state.auth);

  const refreshLogin = () => {
    if (authenticated) {
      return;
    }
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        localStorage.clear();
        dispatch(logoutAction());
        history.push('/login', { from: location });
        return;
    }
    try {
        const currentLocation = { ...location };
        IdentityApi.refreshToken({ token: refreshToken }).subscribe(tokenRes => {
            if (tokenRes) {
                dispatch(setAuthenticated(true));
                dispatch(loginSuccess({
                    user: {
                        accountGuid: tokenRes.GUID,
                        userName: tokenRes.username,
                        roles: [],
                        permissions: []
                    },
                    token: tokenRes.token,
                    refreshToken: tokenRes.refreshToken,
                    remember: true
                }));
                history.replace(currentLocation);
            }
        });
    } catch (err) {
        console.log(err);
    }
  };

  useEffect(() => {
    refreshLogin();
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default Auth;
