/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import EmployeeApi from "api/employee/employee.api";
import IdentityApi from "api/identity/identity.api";
import { NewResponseLogin } from "common/define-identity";
import { RootEpic } from "common/define-type";
import jwt from 'jwt-decode' // import dependency
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { setAuthenticated } from '../auth.slice';
import {
    clearMessage,
    clearMessageResquest,
    forgotRequest,
    getInfoUser,
    loginRequest,
    loginSuccess,
    message,
    messageForgot,
} from "./login.slice";

const login$: RootEpic = (action$) =>
    action$.pipe(
        filter(loginRequest.match),
        switchMap((re) => {
            // IdentityApi.login(re.payload)
            const body = {
                username: re.payload.loginName,
                password: re.payload.password,
                remember: re.payload.remember
            };

            return IdentityApi.login(body).pipe(
                mergeMap((res: any) => {
                    if (res && typeof !res.error) {
                        const newRes = res as NewResponseLogin;
                        return [
                            loginSuccess({
                                user: {
                                    userName: newRes.username,
                                    accountGuid: newRes.GUID,
                                    roles: [],
                                    permissions: []
                                },
                                token: newRes.token,
                                refreshToken: newRes.refreshToken,
                                remember: re.payload.remember
                            }),
                            setAuthenticated(true),
                        ];
                    } else {
                        return [
                            message({
                                content: res.error,
                            }),
                        ];
                    }
                }),
                catchError((err) => [message({ content: "Lỗi đăng nhập" })])
            );
        })
    );
const forgot$: RootEpic = (action$) =>
    action$.pipe(
        filter(forgotRequest.match),
        switchMap((re) => {
            return IdentityApi.forgotPassword(re.payload).pipe(
                map((res: any) => {
                    return messageForgot({ Message: "success" });
                }),
                catchError((err) => [messageForgot(err.response)])
            );
        })
    );
const clearMessage$: RootEpic = (action$) =>
    action$.pipe(
        filter(clearMessageResquest.match),
        map(() => {
            return clearMessage();
        })
    );

const getProfileEmployee$: RootEpic = (action$) =>
    action$.pipe(
        filter(loginSuccess.match),
        switchMap((re) => {
            const userRes: any = jwt(re.payload.token)
            return EmployeeApi.getEmployeeProfileByAccount(
                userRes.GUID
            ).pipe(
                map((res: any) => {
                    return getInfoUser(res[0]);
                }),
                catchError((err) => [messageForgot(err.response)])
            );
        })
    );

export const LoginEpics = [login$, forgot$, clearMessage$, getProfileEmployee$];
