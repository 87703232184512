import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetDepartmentDTO } from "common/models/department.model";
import { Employee, IInfoEmployee } from "common/models/employee.model";
import { InforDepartment } from "common/models/subDepartment.model";

interface DepartmentState {
    companyId: string,
    loading: boolean
    listDepartment: IGetDepartmentDTO[],
    message: string,
    infoEmployee: IInfoEmployee | null,
    contextVisibility: {[id: string]: boolean},
    employeeSelected?: Employee,
    inforDepartments: InforDepartment[],
    supervisor?: InforDepartment
}

const initDepartmentState: DepartmentState = {
    loading: false,
    companyId: "326f2790-dbd3-11ec-aa3d-0242ac180002",
    listDepartment: [],
    message: "",
    infoEmployee: null,
    contextVisibility: {},
    inforDepartments: []
}

const departmentSlice = createSlice({
    name: 'department',
    initialState: initDepartmentState,
    reducers: {
        getDepartmentRequest(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        getDepartmentSuccess(state, action: PayloadAction<IGetDepartmentDTO[]>) {
            state.listDepartment = action.payload;
            state.loading = false;
        },
        messageForgot(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setInfoEmployee(state, action: PayloadAction<IInfoEmployee>) {
            state.infoEmployee = action.payload;
        },
        setContextVisibility(state, action) {
            const { guid, visible } = action.payload;
            state.contextVisibility[guid] = visible;
        },
        setEmployeeSelected(state, action) {
            state.employeeSelected = action.payload;
        },
        setInforDepartments(state, action) {
            state.inforDepartments = action.payload;
        },
        setSupervisor(state, action) {
            state.supervisor = action.payload;
        }
    }
})

export const {
    getDepartmentRequest,
    getDepartmentSuccess,
    messageForgot,
    setInfoEmployee,
    setContextVisibility,
    setEmployeeSelected,
    setInforDepartments,
    setSupervisor
} = departmentSlice.actions;


export const departmentReducer = departmentSlice.reducer