/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseDeparment } from "common/define-identity";
import { Login, User } from "common/define-type";
import { Employee } from "common/models/employee.model";
import { WritableDraft } from "immer/dist/internal";

type MessageLogin = {
    content: string;
    errorCode?: number;
};
type MessageForgot = {
    ErrorCode?: number;
    Message: string;
};
interface LoginState {
    loading: boolean;
    isSuccess: boolean;
    user: User | undefined;
    profile: Employee | undefined;
    message: MessageLogin | undefined;
    messageForgot: MessageForgot | undefined;
    refresh_token: string;
    token: string;
}

const initState: LoginState = {
    loading: false,
    isSuccess: false,
    user: undefined,
    message: undefined,
    messageForgot: undefined,
    profile: undefined,
    refresh_token: "",
    token: "",
};

const loginSlice = createSlice({
    name: "login",
    initialState: initState,
    reducers: {
        loginRequest(state, action: PayloadAction<Login>) {
            state.loading = true;
        },
        loginSuccess(
            state,
            action: PayloadAction<{ user: User; token: string, refreshToken: string, remember:boolean }>
        ) {
            const { token, refreshToken, remember } = action.payload;
            // eslint-disable-next-line
            const [_header, payload, _signature] = token.split('.');
            try {
                const decodedProfile = Buffer.from(payload, 'base64').toString();
                const { username, GUID, roles, permissions } = JSON.parse(decodedProfile);
                const user = {
                    userName: username,
                    accountGuid: GUID,
                    roles: roles,
                    permissions: permissions
                };
                state.user = user;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                if (remember) {
                    localStorage.setItem('refresh_token', refreshToken);
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log('There was an error in decoding your profile');
            }
            state.token = token;
            state.loading = false;
            state.isSuccess = true;
        },
        getInfoUser(state, action: PayloadAction<Employee>) {
            state.profile = action.payload;
        },
        forgotRequest(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        sendMailSuccess(
            state,
            action: PayloadAction<{
                message: WritableDraft<MessageLogin> | undefined;
            }>
        ) {
            state.message = action.payload.message;
            state.loading = false;
            state.isSuccess = true;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        getDepartmentRequest(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        getDepartmentSuccess(state, action: PayloadAction<ResponseDeparment>) {
            state.isSuccess = true;
        },
        message(state, action: PayloadAction<MessageLogin>) {
            state.message = action.payload;
            state.loading = false;
        },
        messageForgot(state, action: PayloadAction<MessageForgot>) {
            state.messageForgot = action.payload;
            state.loading = false;
        },
        clearMessageResquest(state) {
            state.loading = true;
        },
        clearMessage(state) {
            state.messageForgot = undefined;
            state.message = undefined;
            state.loading = false;
        },
        setRefreshToken(state, action) {
            const { token, refreshToken } = action.payload;
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refreshToken);
            state.token = token;
            state.refresh_token = refreshToken;
        },
        logoutAction(state) {
            state.isSuccess = false;
            state.token = "";
            state.refresh_token = "";
            state.user = undefined;
            state.profile = undefined;
        }
    },
});

export const {
    // getDepartmentRequest,
    loginRequest,
    forgotRequest,
    clearMessageResquest,
    getInfoUser,
    loginSuccess,
    message,
    messageForgot,
    clearMessage,
    logoutAction,
    setRefreshToken
} = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
