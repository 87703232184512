const SYSTEM_CONSTANTS = {
    API: {
        IDENTITY: {
            CONNECT_TOKEN: "identity/clients/publicKey",
            LOGIN: "auth/login",
            REFRESH_TOKEN: "auth/refreshToken",
            FORGOT: "license_manager/users",
            CREATE_ACCOUNT: "auth/account",
            UPDATE_ACCOUNT: "auth/updateaccount",
            DELETE_ACCOUNT: "auth/deleteaccount",
            UPDATE_PASSWORD: "auth/updatePassword",
            RESET_PASSWORD: "auth/account/resetPassword",
            ASSIGNROLETOACCOUNTS:"auth/assignRoleToAccounts",
            REMOVEROLEFROMACCOUNT:"auth/removeRoleFromAccount",
            GET_ACCOUNTROLES:"auth/account/getAccountRoles"
        },
        OS_TSC: {
            DEPARTMENT_SERVICE: {
                DEPARTMENT: "api/department",
            },
            SUBDEPARTMENT: {
                SUBDEPARTMENT: "api/subdepartment/department",
                GET_ALL_DEPARTMENT: "api/subdepartment/getSubByGuidParent",
                CM_SUB_DEPARTMENT: "api/subdepartment"
            },
            EMPLOYEE: {
                INSERT_PROFILE: "api/employee",
                GET_APPLICANT_EMPLOYEE: "api/employee/employeeByDepartment",
                GET_PROFILE_BY_ACCOUNT: "api/employee/account",
                GET_BY_GUID: "api/employee/getOne",
                GET_TIME_OFF_LIMIT: "api/employee",
                EDIT_TIME_OFF_LIMIT: "api/timeofflimit/limit",
                SWAP_EMPLOYEE: "api/employee/swapEmployee",
                ASIGN_ROLE_APPRVOED_APPLICANT: "api/employee/asignRoleApprovedApplicant"
            },
            TIME_OFF_TYPE: {
                GET_TYPE: "api/timeofftype",
            },
            TIME_OFF_ITEM: {
                GET_ITEM: "api/timeoffitem",
                GET_BY_GUID: "api/timeoffitem/getOne",
            },
            APPLICANT: {
                CREATE: "api/applicant",
                GET_HISTORY: "api/applicant/employee",
                UPLOAD_FILE: "api/images/uploads",
                GET_FILE: "api/download",
                GET_LIST_APPLICANT: "api/applicant/getApplicantByEmployeeIds",
                LEAVE_APPROVED_LIMIT: "api/leaveapprovedlimit",
                FILTER_LEAVE_APPROVED_LIMIT: "api/leaveapprovedlimit/getLeaveByFilter",
                MANY_UPDATE_LEAVE_APPROVED_LIMIT: "api/leaveapprovedlimit/manyUpdate"
            },
            REASON_TIME_OF: {
                GET_REASON: "api/reasonTimeOff"
            },
            STATS: {
                GET_STATS_TIME_OFF: "api/stats/statsTimeOff"
            }
        },
    },
};

export default SYSTEM_CONSTANTS;
