import { createSlice } from "@reduxjs/toolkit";

interface Authentication {
    authenticated: boolean;
}

const AuthenticationInit: Authentication = {
    authenticated: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState: AuthenticationInit,
    reducers: {
        setAuthenticated(state, action) {
            state.authenticated = action.payload;
        }
    }
});

export const {
    setAuthenticated
} = authSlice.actions;

export const authReducer = authSlice.reducer;