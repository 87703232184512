/* eslint-disable */
import SYSTEM_CONSTANTS from "common/constants";
import { Applicant, CreateApplicantRequest, IAddLeaveApprovedLimit, IFilterLeaveApprovedLimit, IGetHistoryApplicant, IGetListApplicant, IPostLeaveApprovedLimit, IQueryTableAbsence } from "common/models/applicant.model";
import { Employee } from "common/models/employee.model";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";

export default class ApplicantApi {
    static host = "";

    static exportPDF(data: any): Observable<any> {
        console.log(data)
        const api = `${ApplicantApi.host}/api/downloadpdf`;
        return HttpClient.post(api, data, {
            'headers': {
                'accept': 'application/pdf'
            },
            responseType: 'arraybuffer',
        });
    }

    static exportCalendarLeaveToPDF(data: any): Observable<any> {
        const api = `${ApplicantApi.host}/api/exportCalendarLeaveToPdf`;
        return HttpClient.post(api, data, {
            'headers': {
                'accept': 'application/pdf'
            },
            responseType: 'arraybuffer',
        });
    }

    static getApplicant(guid: string): Observable<Applicant | null> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.CREATE}/${guid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => (res as Applicant) || null
            )
        );
    }
    static createApplicant(data: CreateApplicantRequest): Observable<string | null> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.CREATE}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => (res as string) || null,
                catchError((_) => new Observable())
            )
        );
    }
    static getHistoryApplicant(employeeGuid: string): Observable<IGetHistoryApplicant[] | []> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.GET_HISTORY}/${employeeGuid}`;
        return HttpClient.get(api).pipe(
            map((res) => res as IGetHistoryApplicant[] | []));
    }
    static updateStatusApplicant(guid: string , data: Applicant): Observable<boolean> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.CREATE}/${guid}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => (res as boolean),
                catchError((_) => new Observable())
            )
        );
    }
    static upLoadFileApplicant(body: FormData): Observable<string[] | []> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.UPLOAD_FILE}`;
        return HttpClient.upload(api, body).pipe(
            map((res) => res as string[] | []));
    }
    static getListApplicantByEmployeeIds(data: IQueryTableAbsence): Observable<IGetListApplicant[] | []> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.GET_LIST_APPLICANT}`;
        return HttpClient.post(api, data).pipe(
            map((res) => res as IGetListApplicant[] | []));
    }
    static findListApplicantToReport(data: any): Observable<{applicantList: IGetListApplicant[], manager: Employee}> {
        const api = `${ApplicantApi.host}/api/applicant/findListApplicantToReport`;
        return HttpClient.post(api, data).pipe(
            map(res => res as any)
        );
    }
    static addLeaveApprovedLimit(data: IAddLeaveApprovedLimit): Observable<string | null> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.LEAVE_APPROVED_LIMIT}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => (res as string) || null,
                catchError((_) => new Observable())
            )
        );
    }
    static postFilterLeaveApprovedLimit(data: IFilterLeaveApprovedLimit): Observable<IPostLeaveApprovedLimit[] | []> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.FILTER_LEAVE_APPROVED_LIMIT}`;
        return HttpClient.post(api, data).pipe(
            map((res) => res as IPostLeaveApprovedLimit[] | [])
        );
    }

    static putManyUpadteLeaveApprovedLimit(data: IPostLeaveApprovedLimit[]): Observable<IPostLeaveApprovedLimit[] | []> {
        const api = `${ApplicantApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.APPLICANT.MANY_UPDATE_LEAVE_APPROVED_LIMIT}`;
        return HttpClient.put(api, data).pipe(
            map((res) => res as IPostLeaveApprovedLimit[] | [])
        );
    }

}
