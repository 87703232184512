import { History } from 'history';
import { logoutAction, setAuthenticated, setRefreshToken } from "redux/controller";
import { defer, throwError } from "rxjs";
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map, retry, switchMapTo } from "rxjs/operators";
import IdentityApi from "./identity/identity.api";

/** types */
type PartAjaxRequest = Omit<AjaxRequest, "url" | "method" | "body">;
type HttpMethod = "GET" | "POST" | "DELETE" | "PUT";
type HeadersAjax = {
    Authorization: string;
    Accept: string;
    "Content-Type": string;
    "Sec-Fetch-Site"?: string;
};
interface Param {
    url: string;
    data?: unknown;
    headers?: PartAjaxRequest;
}

/** functions */
let history: any;
export const setupHTTP = (historyLocation: History<any>): void => {
    history = historyLocation
}

let store: any;
export const injectStore = (reduxStore: any) : void => {
    store = reduxStore;
}

function mapResponse(res: AjaxResponse) {
    if (res.response) {
        return res.response.data || res.response;
    }
}
function mapResponseHeader(res: AjaxResponse) {
    if (res.response) {
        return res;
    }
}

const navigationLogin = () => {
    localStorage.clear();
    // const { dispatch } = store;
    // dispatch(setAuthenticated(false));
    // dispatch(logoutAction());
    document.location.href = '/';
}

const refreshAccessToken = (refreshToken: string | null) => {
    return IdentityApi.refreshToken({token: refreshToken}).pipe(map(tokenRes => {
        if (tokenRes && store) {
            const { dispatch } = store;
            dispatch(setRefreshToken({token: tokenRes.token, refreshToken: tokenRes.refreshToken}));
            return tokenRes.token;
        } else {
            navigationLogin();
        }
    }, catchError(err => {
        navigationLogin();
        return throwError(err);
    })));
}

function handleError$(err: AjaxError): Observable<unknown> {
    if(err) {
        const refreshToken = localStorage.getItem('refresh_token');
        if(err.status === 401 && refreshToken) {
            return refreshAccessToken(refreshToken);
        }
    }
    // navigationLogin();
    return throwError(err);
}

function mapAjaxRequest(request?: PartAjaxRequest) {
    const token = localStorage.getItem("token");
    const mapHeaders = request?.headers
        ? ({ ...request.headers } as HeadersAjax)
        : undefined;
    const newHeaders = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
        timezone: -new Date().getTimezoneOffset() / 60,
        ...mapHeaders,
    };
    return { ...request, headers: { ...newHeaders } };
}

function commonApiCall(
    method: HttpMethod,
    param: Param,
    isGetHeader = false
): Observable<unknown> {
    const { url, data, headers } = param;
    const body = data;
    return defer(() => {
        const newHeaders = mapAjaxRequest(headers);
        return ajax({ url, method, body, ...newHeaders, timeout: 5 * 60 * 1000 }); // timeout: 5 phút
    }).pipe(
        map(res => !isGetHeader ? mapResponse(res) : mapResponseHeader(res)),
        catchError((err) => handleError$(err).pipe(switchMapTo(throwError(err)))),
        // retry(1)
    );
}

/** base class */
export default class HttpClient {
    static get(url: string, headers?: PartAjaxRequest, isGetHeader?: boolean): Observable<unknown> {
        return commonApiCall("GET", { url, headers });
    }

    static post(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest,
        isGetHeader?: boolean
    ): Observable<unknown> {
        return commonApiCall("POST", { url, data, headers }, isGetHeader);
    }

    static delete(url: string, data?: unknown, headers?: PartAjaxRequest): Observable<unknown> {
        return commonApiCall("DELETE", { url, data, headers });
    }

    static put(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest
    ): Observable<unknown> {
        return commonApiCall("PUT", { url, data, headers });
    }
    static upload(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest
    ): Observable<unknown> {
        const newHeaders = mapAjaxRequest(headers);
        const { headers: newHeadersUpload, ...res } = newHeaders;
        const { "Content-Type": tem, ...resUpload } = newHeadersUpload;

        return defer(() => {
            const resultHeaders = { ...res, headers: resUpload };
            return ajax({ url, method: 'POST', body: data, ...resultHeaders });
        }).pipe(
            map(res => mapResponse(res)),
            catchError((err) => handleError$(err).pipe(switchMapTo(throwError(err)))),
            retry(1)
        );
    }
}
