import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
    AppEpics,
    appReducer,
    BoostrapEpics,
    bootstrapReducer,
    LoginEpics,
    loginReducer,
    modalReducer,
    SubDepartmentEpics,
    TimeOffEpics,
    timeOffReducer,
    authReducer
} from "./controller";
import { DepartmentEpics } from "./controller/department/department.epic";
import { departmentReducer } from "./controller/department/department.slice";
import { subDepartmentReducer } from "./controller/subDepartment/subDepartment.slice";

// import { hotelReducer, HotelEpics } from "./controller/hotel.slice";
const rootReducer = combineReducers({
    login: loginReducer,
    app: appReducer,
    bootstrap: bootstrapReducer,
    department: departmentReducer,
    subDepartment: subDepartmentReducer,
    timeOff: timeOffReducer,
    modal: modalReducer,
    auth: authReducer
});

export const rootEpic = combineEpics(
    ...LoginEpics,
    ...BoostrapEpics,
    ...DepartmentEpics,
    ...AppEpics,
    ...SubDepartmentEpics,
    ...TimeOffEpics,
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
