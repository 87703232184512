/* eslint-disable */
import IdentityApi from "api/identity/identity.api";
import SYSTEM_CONSTANTS from "common/constants";
import { AssignRoleToAccountRequest, CreateAccountRequest, RemoveRoleFromAccount, UpdateAccountValidRequest } from "common/define-identity";
import { SearchTimeOff } from "common/define-timeoff/define-timeoff";
import { CreateEmployeeReq } from "common/employee/EmployeeRequest";
import { Employee, IGetRoleClaim, IGetTimeOffLimmit, IPutTimeOffLimit, RestoreEmployee, SwapEmployee } from "common/models/employee.model";
import { IQueryTimeOffLimit } from "common/models/timeOffType.model";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import HttpClient from "../http-client";

export default class EmployeeApi {
    static host = "";

    static getListEmployeeDeleted(): Observable<any>{
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}/getAllEmployeeDeleted/0`;
        return HttpClient.get(api).pipe(
            map(
                (res) => res as [],
                catchError((_) => new Observable())
            )
        );
    }

    static restoreEmployeeDeleted(data: RestoreEmployee,AccountGuid: string): Observable<any>{
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}/${AccountGuid}`;
        return HttpClient.put(api,data).pipe(
            map(
                (res) => res as any,
                catchError((_) => new Observable())
            )
        );
    }

    static createProfile(data: CreateEmployeeReq): Observable<string | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => (res as string) || null,
                catchError((_) => new Observable())
            )
        );
    }

    static updateProfile(data: Employee): Observable<string | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}/${data.GUID}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => (res as string) || null,
                catchError((_) => new Observable())
            )
        );
    }

    static createAccount(data: CreateAccountRequest): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.CREATE_ACCOUNT}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static getRoleByGuid(guid: string): Observable<IGetRoleClaim[]> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.GET_ACCOUNTROLES}/${guid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static assignRoleAccount(data: AssignRoleToAccountRequest): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.ASSIGNROLETOACCOUNTS}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static removeRoleAccount(data: RemoveRoleFromAccount): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.REMOVEROLEFROMACCOUNT}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static updateAccount(data: UpdateAccountValidRequest): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.UPDATE_ACCOUNT}/${data.GUID}`;
        return HttpClient.put(api, {outDated: data.outDated}).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }

    static updatePassword(data: any): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.UPDATE_PASSWORD}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }

    static resetPassword(data: {accountGuid: string, password: string}): Observable<any> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.RESET_PASSWORD}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }

    static getAllEmployee(): Observable<Employee[] | []> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => res as Employee[] | [],
                catchError((error) => new Observable())
            )
        );
    }
    static getApplicantEmployee(departmentGuid: string, pageNumber: number, pageSize: number, formSearch?: SearchTimeOff): Observable<any> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.GET_APPLICANT_EMPLOYEE}/${departmentGuid}?pageSize=${pageSize}&pageNumber=${pageNumber}`;

        return HttpClient.post(api, formSearch).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static getEmployeeProfileByAccount(accountGuid: string): Observable<any> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.GET_PROFILE_BY_ACCOUNT}/${accountGuid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static getEmployeeByGuid(guid: string): Observable<Employee> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}/${guid}`;
        return HttpClient.get(api).pipe(
            map(
                (res) => res as Employee,
                catchError((error) => new Observable())
            )
        );
    }

    static deleteProfile(profileGuid: string): Observable<boolean | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.INSERT_PROFILE}/${profileGuid}`;
        return HttpClient.delete(api).pipe(
            map(
                (res) => res as boolean | null,
            )
        );
    }
    static getTimeOffLimitByEmployeeGuid(employeeGuid: string, data: IQueryTimeOffLimit): Observable<IGetTimeOffLimmit> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.GET_TIME_OFF_LIMIT}/${employeeGuid}/timeofflimit`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => res as IGetTimeOffLimmit,
            )
        );
    }
    static editTimeOffLimitByEmployeeGuid(employeeGuid: string, data: IPutTimeOffLimit): Observable<boolean> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.EDIT_TIME_OFF_LIMIT}/${employeeGuid}`;
        return HttpClient.post(api, data).pipe(
            map(
                (res) => res as boolean,
            )
        );
    }
    static getRoleByListAccountGuid(accountIds: string[]): Observable<IGetRoleClaim[]> {
        const api = `${IdentityApi.host}/${SYSTEM_CONSTANTS.API.IDENTITY.GET_ACCOUNTROLES}`;
        return HttpClient.post(api, { accountIds }).pipe(
            map(
                (res) => res as any,
                catchError((error) => new Observable())
            )
        );
    }
    static transferEmployee(data: SwapEmployee, guid: string): Observable<boolean | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.SWAP_EMPLOYEE}/${guid}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => res as boolean,
            )
        );
    }
    static asignRoleApprovedApplicantByEmployeeId(
        employeeGuid: string, 
        allowDepartmentApproveRequest: boolean, 
        subDepartmentGuidApproved: string,
        allowDepartmentRequestView: boolean,
        subDepartmentGuidView: string
    ): Observable<boolean | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.ASIGN_ROLE_APPRVOED_APPLICANT}/${employeeGuid}`;
        return HttpClient.put(api, { allowDepartmentApproveRequest, subDepartmentGuidApproved, allowDepartmentRequestView, subDepartmentGuidView }).pipe(
            map(
                (res) => res as boolean,
            )
        );
    }
    static AcceptReciveTimeOffByEmployee(employeeGuid: string | undefined, data: any  ): Observable<boolean | null> {
        const api = `${EmployeeApi.host}/${SYSTEM_CONSTANTS.API.OS_TSC.EMPLOYEE.ASIGN_ROLE_APPRVOED_APPLICANT}/${employeeGuid}`;
        return HttpClient.put(api, data).pipe(
            map(
                (res) => res as boolean,
            )
        );
    }
}
